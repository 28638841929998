
import { JoinModule } from '@/store/join';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Form } from 'element-ui';
import { updateUserInfo } from '@/api/user';
import { UserModule } from '@/store/modules/user';
import { getJwtInfo, removeToken } from '@/utils/cookies';
import moment from 'moment';
import { saveFcmToken } from '@/api/fcm-token';

@Component({
  name: 'joinStep4',
  components: {
  },
})

export default class extends Vue {
  get form() {
    return JoinModule.form;
  }

  async mounted() {
    // To-do
    // 플러터 웹뷰가 응답할 준비가 되었는지 체크하는 부분이지만, 응답이 오지 않아서 주석처리함.
    // 일부 환경에서 flutter_inappwebview.callHandler 부분을 찾을 수 없다고 에러가 뜰 가능성이 있음
    // await window.addEventListener('flutterInAppWebViewPlatformReady', async (event) => {
      //  await (window as any).flutter_inappwebview.callHandler('fcmToken').then((result: any) => {
        // this.fcmToken.token = result.fcmToken;
      // }).catch((error: any) => {
        // console.error('Error getting FCM Token:', error); // 에러 로그 확인
      // });
    // });
    this.checkSocialUser();
  }

  private fcmToken = {
    token: '',
  }

  private tipVisible = false;

  private rules: any = {
    dietExperience: [
      { required: true, message: '식단관리 경험을 체크하세요.', trigger: ['blur', 'change'] },
    ],
    height: [
      { required: true, message: '키를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    weight: [
      { required: true, message: '몸무게를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    goalWeight: [
      { required: true, message: '목표 몸무게를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    goalDate: [
      { required: true, message: '목표 달성 날짜를 입력하세요.', trigger: ['blur', 'change'] },
    ],
    activityLevel: [
      { required: true, message: '활동 수준을 선택하세요.', trigger: ['blur', 'change'] },
    ],
    dietPurpose: [
      { required: true, message: '식단관리 목적을 선택하세요.', trigger: ['blur', 'change'] },
    ],
  };

  private handleJoin() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        updateUserInfo(this.form).then(async () => {
          this.$message.success('회원가입이 완료되었습니다.');
          if (this.fcmToken.token !== '') await saveFcmToken(this.fcmToken);
          window.localStorage.removeItem('isRegister');
          this.$router.push({ name: 'Login' });
        });
      }
    });
  }

  private computeGoalDate() {
    let gap = (this.form.weight - this.form.goalWeight);
    if (gap < 0) {
      gap *= -1;
    }
    this.form.goalDate = (moment().add(gap / 0.5, 'weeks').format('YYYY-MM-DD'));
    this.tipVisible = true;
  }

  private checkSocialUser() {
    /* eslint-disable */
    const token = UserModule.token;
    if (token) {
      const status = getJwtInfo('status');
      if (status) {
        this.$router.push({ name: 'Home' });
      } else {
        if (this.form.step !== 3) {
          this.$router.push({ name: 'Agree' });
        }
      }
    } else {
      this.$router.push({ name: 'Login' });
    }
    /* eslint-enable */
  }
}
